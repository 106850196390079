<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical' && grpCreditUser">
    <ng-container *ngFor="let item of menu">
        <!-- section -->
        <li core-menu-vertical-section *ngIf="item.type == 'section' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)" [item]="item" class="navigation-header"></li>

        <!-- sub-menu item/collapsible of sections  -->
        <ng-container *ngIf="item.type == 'section'">
            <ng-container *ngFor="let item of item.children">
                <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
                <li core-menu-vertical-item *ngIf="
            item.type == 'item' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)
          " [item]="item" [ngClass]="{ disabled: item.disabled === true }" [routerLink]="item.openInNewTab ? [] : [item.url]" [routerLinkActive]="!item.openInNewTab ? 'active' : ''" [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"></li>
                <!-- collapsible -->
                <li core-menu-vertical-collapsible *ngIf="item.type == 'collapsible' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)" [item]="item" class="nav-item has-sub"></li>
            </ng-container>
        </ng-container>

        <!-- item -->
        <li core-menu-vertical-item *ngIf="
        item.type == 'item' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)
      " [item]="item" [ngClass]="{ disabled: item.disabled === true }" [routerLink]="item.openInNewTab ? [] : [item.url]" [routerLinkActive]="!item.openInNewTab ? 'active' : ''" [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"></li>
        <!-- collapsible -->
        <li core-menu-vertical-collapsible *ngIf="item.type == 'collapsible' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)" [item]="item" class="nav-item has-sub"></li>
    </ng-container>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="layout === 'horizontal'">
    <ng-container *ngFor="let item of menu">
        <!-- section -->
        <li core-menu-horizontal-collapsible *ngIf="item.type == 'section' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)" [item]="item" class="dropdown nav-item"></li>

        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li core-menu-horizontal-item *ngIf="item.type == 'item' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)" [item]="item" [ngClass]="{ disabled: item.disabled === true }" [routerLink]="item.openInNewTab ? [] : [item.url]" [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
            [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"></li>

        <!-- collapsible -->
        <li core-menu-horizontal-collapsible *ngIf="item.type == 'collapsible' && (item.role ? encontrarRol(item.role) : false || item.role == undefined)" [item]="item" class="dropdown dropdown-submenu"></li>
    </ng-container>
</ng-container>
<!--/ Horizontal Menu -->